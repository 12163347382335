import dynamic from 'next/dynamic';

import type { AdvancedCopyBlockSectionProps } from 'src/components/sections/AdvancedCopyBlockSection/AdvancedCopyBlockSection';
import type { SectionRenderer } from 'src/types/Section';
import type { Transformer } from 'src/types/Transformer';
import type { AdvancedCopyBlockContentSection } from './types';

/* istanbul ignore next */
export const renderer: SectionRenderer<AdvancedCopyBlockSectionProps> =
  dynamic<AdvancedCopyBlockSectionProps>(() =>
    import('src/components/sections/AdvancedCopyBlockSection/AdvancedCopyBlockSection').then(
      (mod) => mod.AdvancedCopyBlockSection,
    ),
  );

export const transformer: Transformer<
  AdvancedCopyBlockContentSection,
  AdvancedCopyBlockSectionProps
> = ({ _metadata, copy, tracking_title }) => ({
  uid: _metadata.uid,
  props: {
    children: copy,
    trackingTitle: tracking_title,
  },
});
